const SIDE_LIST = [
  {
    title: '說明',
    routerParam: {
      name: 'adminHome'
    }
  },
  {
    title: '首頁輪轉圖',
    routerParam: {
      name: 'adminBanners'
    }
  }
]

export { SIDE_LIST }
