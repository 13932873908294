<template>
  <ImageBlock class="image-add-block" ref="imageBlock" @change="onImageChange">
    <IconPlus class="image-add-block__icon" @click.native="onAddImage" />
  </ImageBlock>
</template>

<script>
import ImageBlock from './ImageBlock'
import IconPlus from '@/components/icons/IconPlus'

export default {
  name: 'ImageAddBlock',
  components: {
    ImageBlock,
    IconPlus
  },
  data() {
    return {}
  },
  methods: {
    onAddImage() {
      this.$refs.imageBlock.onClickInput()
    },
    onImageChange(files) {
      this.$emit('add', files)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-add-block {
  .image-add-block__icon {
    width: 50px;
    height: 50px;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
