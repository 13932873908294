<template>
  <div class="full-loading">
    <div class="loader">
      <div v-for="index in 6" :key="index" class="loader__dot" />
      <p class="loader__text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullLoading'
}
</script>

<style lang="scss" scoped>
.full-loading {
  $loader--width: 250px;
  $loader-dot--size: 20px;

  height: 100vh;
  width: 100vw;
  font-family: Helvetica;

  .loader {
    height: $loader-dot--size;
    width: $loader--width;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .loader__dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: $loader-dot--size;
    width: $loader-dot--size;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;

    &:first-child {
      background-color: #8cc759;
      animation-delay: 0.5s;
    }

    &:nth-child(2) {
      background-color: #8c6daf;
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      background-color: #ef5d74;
      animation-delay: 0.3s;
    }

    &:nth-child(4) {
      background-color: #f9a74b;
      animation-delay: 0.2s;
    }
    &:nth-child(5) {
      background-color: #60beeb;
      animation-delay: 0.1s;
    }

    &:nth-child(6) {
      background-color: #fbef5a;
      animation-delay: 0s;
    }
  }

  .loader__text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;

    &:after {
      content: 'Loading';
      font-weight: bold;
      animation-name: loading-text;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes loader {
    15% {
      transform: translateX(0);
    }
    45% {
      transform: translateX($loader--width - $loader-dot--size);
    }
    65% {
      transform: translateX($loader--width - $loader-dot--size);
    }
    95% {
      transform: translateX(0);
    }
  }
  @keyframes loading-text {
    0% {
      content: 'Loading';
    }
    25% {
      content: 'Loading.';
    }
    50% {
      content: 'Loading..';
    }
    75% {
      content: 'Loading...';
    }
  }
}
</style>
