<template>
  <div class="login">
    <form class="login-block" @submit.prevent="onLogin">
      <p class="block__title">{{ $t('admin.login.title') }}</p>
      <div class="block__account">
        <p class="account__text">{{ $t('admin.login.account') }}</p>
        <input
          class="account__input"
          v-model="account"
          autocomplete="off"
          autofocus
        />
      </div>
      <div class="block__password">
        <p class="password__text">{{ $t('admin.login.password') }}</p>
        <input
          class="password__input"
          type="password"
          v-model="password"
          autocomplete="off"
        />
      </div>
      <p class="block__error-message">{{ errorMessage }}</p>
      <button
        class="block__login-button"
        :disabled="isDisabled"
        @click="onLogin"
      >
        {{ $t('admin.login.title') }}
      </button>
      <input style="display: none" type="submit" />
    </form>
  </div>
</template>

<script>
import FirebaseAPI from '@/api/firebaseAPI'

import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      account: '',
      password: '',
      errorMessage: ''
    }
  },
  computed: {
    isDisabled() {
      return !(this.account && this.password)
    }
  },
  methods: {
    ...mapActions(['loginState']),
    onInputChange() {
      this.errorMessage = ''
    },
    async onLogin() {
      try {
        const res = await FirebaseAPI.login(this.account, this.password)
        this.$router.push({
          name: 'adminHome'
        })
        this.loginState(res.user)
      } catch (error) {
        this.errorMessage = error.message
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-block {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 520px;
    background-color: $blue-and-green;
    border: 1px solid $dark-blue;
    border-radius: 4px;
    box-sizing: border-box;

    > * + * {
      margin-top: 16px;
    }
  }

  .block__title {
    font-size: 20px;
    font-weight: bold;
  }

  .block__account,
  .block__password {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;

    @include ipad {
      font-size: 20px;
    }
  }

  .account__input,
  .password__input {
    width: 40%;
    padding: 0 6px;
    margin-left: 10px;
    height: 26px;
    border-radius: 4px;
    border: 1px solid;
    box-sizing: border-box;

    @include mobile {
      width: initial;
      flex: 1;
      height: 30px;
    }
  }

  .block__error-message {
    color: $red;
  }

  .block__login-button {
    font-size: 16px;
    padding: 4px 30px;
    border: 1px solid;
    border-radius: 4px;
    background-color: $light-beige;
    cursor: pointer;
  }
}
</style>
