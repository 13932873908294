<template>
  <div class="container">
    <Loading class="container__loading" v-if="isLoading" />
    <p class="container__title">
      {{ title }}
      <slot name="header" />
    </p>
    <div class="container__divider" />
    <slot />
  </div>
</template>

<script>
import Loading from '@/components/common/Loading'

export default {
  name: 'Container',
  components: {
    Loading
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  flex: 1;
  padding: 20px 16px;
  position: relative;

  .container__loading {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .container__title {
    font-size: 26px;
    font-weight: bold;
    text-align: start;
  }

  .container__divider {
    margin: 26px 0;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: $black;
  }
}
</style>
