import { render, staticRenderFns } from "./BannersControl.vue?vue&type=template&id=4534ae99&scoped=true&"
import script from "./BannersControl.vue?vue&type=script&lang=js&"
export * from "./BannersControl.vue?vue&type=script&lang=js&"
import style0 from "./BannersControl.vue?vue&type=style&index=0&id=4534ae99&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4534ae99",
  null
  
)

export default component.exports