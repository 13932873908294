<template>
  <div
    class="image-block"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <input
      class="image-block__input"
      @change="onImageChange"
      type="file"
      accept="application/pdf,image/*"
      ref="uploadInput"
      :multiple="isMultiple"
    />
    <slot />
  </div>
</template>

<script>
import { Image } from './imageConfig'

export default {
  name: 'ImageBlock',
  props: {
    isMultiple: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    dragover(e) {
      e.preventDefault()
    },
    dragleave(e) {
      e.preventDefault()
    },
    drop(e) {
      e.preventDefault()

      if (!e.target.files) {
        return
      }

      this.uploadFiles(this.filesWithMetadata(e.dataTransfer.files))
    },
    filesWithMetadata(files) {
      const result = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const image = new Image({ name: file.name, file })
        result.push(image)
      }
      return result
    },
    uploadFiles(files) {
      this.$emit('change', files)
    },
    onImageChange(e) {
      if (!e.target.files) {
        return
      }

      this.uploadFiles(this.filesWithMetadata(e.target.files))
    },
    onClickInput() {
      this.$refs.uploadInput.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.image-block {
  .image-block__input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
}
</style>
