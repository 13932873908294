<template>
  <div class="admin">
    <FullLoading v-if="isLoading" />
    <template v-else>
      <Navigation v-if="isShowNavigation" />
      <router-view />
    </template>
  </div>
</template>

<script>
import FullLoading from '@/components/common/FullLoading'
import Navigation from '@/components/admin/navigation/Navigation'
import FirebaseAPI from '@/api/firebaseAPI'

import { mapActions } from 'vuex'

export default {
  name: 'Admin',
  components: {
    FullLoading,
    Navigation
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    isShowNavigation() {
      return !this.$route.meta.hiddenSideMenu
    }
  },
  created() {
    this.checkUserState()
  },
  methods: {
    ...mapActions(['loginState']),
    checkUserState() {
      this.isLoading = true
      FirebaseAPI.checkLoginState(({ success, user }) => {
        this.isLoading = false
        if (success) {
          this.loginState(user)
        } else {
          if (this.$route.name !== 'adminLogin') {
            this.$router.push({
              name: 'adminLogin'
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.admin {
  display: flex;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: $darkest-grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include ipad {
    flex-direction: column;
  }
}
</style>
