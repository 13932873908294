<template>
  <div class="navigation">
    <div v-if="isIpad" class="navigation__header">
      <IconMenu class="header__menu-icon" @click.native="onMenu" />
    </div>
    <SideMenu
      class="side-menu"
      :isShow.sync="isOpenMenu"
      :list="SIDE_LIST"
      :isFix="!isIpad"
    >
      <template #bottomContent>
        <p class="logout" @click="onLogout">{{ $t('common.logout') }}</p>
      </template>
    </SideMenu>
  </div>
</template>

<script>
import IconMenu from '@/components/icons/IconMenu'
import SideMenu from '@/components/common/SideMenu'

import FirebaseAPI from '@/api/firebaseAPI'

import { SIDE_LIST } from './navigationConfig'

export default {
  name: 'navigation',
  components: {
    IconMenu,
    SideMenu
  },
  data() {
    return {
      SIDE_LIST,

      isOpenMenu: false
    }
  },
  methods: {
    onMenu() {
      this.isOpenMenu = !this.isOpenMenu
    },
    onLogout() {
      try {
        FirebaseAPI.logout()
        this.$router.push({
          name: 'adminLogin'
        })
      } catch (error) {
        console.log('logout error : ', error.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  .navigation__header {
    display: flex;
    padding: 10px;
    background-color: $blue-and-green;
  }

  .header__menu-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .side-menu {
    z-index: 10;
    ::v-deep .side-menu__panel {
      background-color: $dark-blue;
    }
  }

  .logout {
    align-self: flex-end;
    cursor: pointer;
  }
}
</style>
