<template>
  <Container class="banners-control" title="首頁輪轉圖" :isLoading="isLoading">
    <template #header>
      <button
        class="banners-control__store"
        @click="onStoreAndUpdateImage"
        :disabled="isDisabled"
      >
        存檔並更新
      </button>
    </template>
    <div class="banners-control__images">
      <div
        class="images__layout-wrap"
        v-for="(image, index) in images"
        :key="image.id"
      >
        <p class="layout__title">第{{ index + 1 }}張</p>
        <div class="image__wrap">
          <p>大螢幕圖片</p>
          <ImageLayout
            :image="image"
            :imageType="IMAGE_TYPE.HDR"
            :handleChange="handleChange"
            :handleDelete="handleDelete"
          />
        </div>
        <div class="image__wrap">
          <p>小螢幕圖片</p>
          <ImageLayout
            :image="image"
            :imageType="IMAGE_TYPE.SD"
            :handleChange="handleChange"
            :handleDelete="handleDelete"
          />
        </div>
        <div class="layout__input-wrap">
          <p class="input__text">文字</p>
          <input
            class="input"
            v-model="image.text"
            placeholder="ex: 陪伴同行  看見希望"
          />
        </div>
        <div class="layout__input-wrap">
          <p class="input__text">連結</p>
          <input
            class="input"
            v-model="image.link"
            placeholder="ex: https://google.com.tw"
          />
        </div>
        <div class="layout__input-wrap">
          <p class="input__text">文字顏色</p>
          <input
            class="input"
            v-model="image.color"
            placeholder="ex: #000000"
          />
        </div>
      </div>
      <ImageAddBlock @add="onAddImagesChange" />
    </div>
  </Container>
</template>

<script>
import Container from '@/components/admin/Container'
import ImageLayout from '@/components/admin/ImageGroup/ImageLayout'
import ImageAddBlock from '@/components/admin/ImageGroup/ImageAddBlock'
import {
  IMAGE_TYPE,
  ImageData,
  Image
} from '@/components/admin/ImageGroup/imageConfig'

import firebaseAPI from '@/api/firebaseAPI'
import ImageAPI from '@/api/ImageAPI'

export default {
  name: 'BannersControl',
  components: {
    Container,
    ImageLayout,
    ImageAddBlock
  },
  data() {
    return {
      IMAGE_TYPE,

      isLoading: true,
      isDisabled: true,
      dbImages: [],
      images: [],
      deleteImages: {
        HDR: [],
        SD: []
      }
    }
  },
  async created() {
    this.isLoading = true
    this.getBannerImages()
    this.isLoading = false
  },
  watch: {
    images: {
      deep: true,
      handler() {
        if (this.isLoading) {
          return
        }
        this.isOpenUploadButton = true
        this.isDisabled = false
      }
    }
  },
  methods: {
    async getBannerImages() {
      const banners = await firebaseAPI.getBanners()
      this.images = banners.map(({ images, text, link, color }) => {
        return new ImageData(images, text, link, color)
      })
      this.dbImages = [...this.images]
    },
    handleChange(oldId, fileData, imageType) {
      const index = this.images.findIndex(image => {
        return image.images[imageType].id === oldId
      })

      this.handleDelete(oldId, imageType)
      this.images[index].setImageWithType(fileData, imageType)
    },
    handleDelete(deleteID, imageType) {
      this.deleteImages[imageType].push(deleteID)

      const index = this.images.findIndex(image => {
        return image.images[imageType].id === deleteID
      })

      this.images[index].images[imageType] = new Image()

      if (
        !this.images[index].images?.HDR?.src &&
        !this.images[index].images?.SD?.src
      ) {
        this.images = this.images.filter((_, i) => {
          return index !== i
        })
      }
    },
    async deleteAllDBBanners() {
      await firebaseAPI.deleteBanners(
        Object.keys(this.dbImages).map(key => String(key))
      )
    },
    async deleteUnnecessaryStorageBanners() {
      await this.deleteStorageBanners(IMAGE_TYPE.HDR)
      await this.deleteStorageBanners(IMAGE_TYPE.SD)
    },
    async deleteStorageBanners(imageType) {
      const allListRes = await ImageAPI.getImageListByFolder(
        `banners/${imageType}`
      )
      if (!allListRes.state) {
        return
      }

      const allFileNames = allListRes.list.items.map(item => {
        return item.name
      })

      const filter = this.deleteImages[imageType].filter(id =>
        allFileNames.includes(id)
      )

      filter.forEach(i => {
        ImageAPI.deleteImage('banners', imageType, filter[i])
      })
    },

    async onStoreAndUpdateImage() {
      this.isDisabled = true
      this.isLoading = true
      await this.deleteAllDBBanners()
      await this.deleteUnnecessaryStorageBanners()
      await firebaseAPI.setBanners(this.images)

      this.isLoading = false
    },
    onAddImagesChange(files) {
      const newImages = files.map(file => {
        return new ImageData({
          HDR: file,
          SD: file
        })
      })
      this.images = this.images.concat(newImages)
    }
  }
}
</script>

<style lang="scss" scoped>
.banners-control {
  .banners-control__images {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    @include ipad {
      flex-direction: column;
    }
  }

  .layout__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  .images__layout-wrap {
    > * + * {
      margin-top: 10px;
    }
  }

  .image__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .layout__input-wrap {
    margin-top: 8px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .input__text {
    font-size: 18px;
  }

  .input {
    flex: 1;
  }

  .banners-control__store {
    margin-left: 20px;
    padding: 10px 16px;
    font-size: 22px;
    background-color: $blue-and-green;
    border-radius: 8px;
    border: 1px solid #88bfc6;
    cursor: pointer;

    &:hover {
      background-color: $light-blue-and-green;
    }

    &:disabled {
      opacity: 0.9;
      cursor: not-allowed;
    }
  }
}
</style>
