<template>
  <ImageBlock
    class="image-layout"
    ref="imageBlock"
    @change="onImageChange"
    :isMultiple="false"
  >
    <img class="image-layout__img" :src="imageWithType.src" />
    <div class="image-layout__tools">
      <button class="tools__change-button button" @click="onChangeButton">
        {{ $t('common.changeImage') }}
      </button>
      <IconTrash
        class="tools__delete-button button"
        @click.native="handleDelete(imageWithType.id, imageType)"
      />
    </div>
  </ImageBlock>
</template>

<script>
import ImageBlock from './ImageBlock'
import IconTrash from '@/components/icons/IconTrash'
import { ImageData } from './imageConfig'

export default {
  name: 'ImageLayout',
  components: {
    ImageBlock,
    IconTrash
  },
  props: {
    image: {
      type: ImageData,
      required: true
    },
    imageType: {
      type: String,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    handleDelete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    imageWithType() {
      return this.image.images[this.imageType] || {}
    }
  },
  methods: {
    onChangeButton() {
      this.$refs.imageBlock.onClickInput()
    },
    onImageChange(files) {
      this.handleChange(this.imageWithType.id, files[0], this.imageType)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0 12px 12px;
  background-color: $grey;
  border-radius: 8px;

  .image-layout__img {
    height: 200px;
    width: 300px;
    overflow: auto;
  }

  .image-layout__tools {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 30px;
  }

  .tools__delete-button {
    width: 30px;
    height: 30px;
    color: $red;

    &:hover {
      color: $light-red;
    }
  }

  .tools__change-button {
    padding: 4px 10px;
    font-size: 16px;
    background-color: $blue-and-green;
    border-radius: 4px;

    &:hover {
      background-color: $light-blue-and-green;
    }
  }

  .button {
    cursor: pointer;
  }
}
</style>
